const maybeReload = require('rewart-frontend-library/src/utils/maybeReload');

const maybe404 = (cb) => {
  return cb.catch((e) => {
    console.error(e);
    return maybeReload(import(/* webpackChunkName: "not-found" */ './views/NotFound'));
  });
};

module.exports = (comp) => () => import('rewart-frontend-library/src/mixins/OnPromoPage').then(({ default: OnPromoPage }) => ({
  name: 'PromoComp',
  mixins: [OnPromoPage],
  inheritAttrs: false,
  beforeRouteEnter(to, from, next) {
    comp(to.params.promotion).then(({default: cmp}) => {
      const mixinsEnter = (cmp.mixins || []).filter((m) => m.beforeRouteEnter);
      const subNext = () => {
        if (cmp.beforeRouteEnter) {
          cmp.beforeRouteEnter(to, from, next);
        } else {
          next();
        }
      };
      const recNext = (next) => {
        const mixin = mixinsEnter.shift();
        if (mixin) {
          mixin.beforeRouteEnter(to, from, () => recNext(next));
        } else {
          next();
        }
      };
      recNext(subNext);
    }).catch(() => {
      next({ name: '404', params: { 0: to.fullPath } });
    });
  },
  render(createElement) {
    return createElement('component', { is: this.comp, props: this.$attrs });
  },
  watch: {
    promotion: {
      handler() {
        this.$root.$children[0].title = this.promotion?.name;
      },
      immediate: true,
    },
    comp: {
      handler(c) {
        if (this.$root.$children[0].observeSections) {
          c().finally(() =>
            setTimeout(() =>
              this.$root.$children[0].observeSections(), 1000));
        }
      }, immediate: true,
    },
  },
  computed: {
    promo() {
      return this.$route.params.promotion;
    },
    comp() {
      return () => maybe404(comp(this.promo));
    },
  },
}));
