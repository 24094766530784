import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/plugins/store';

export default (routes) => {
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.params.noScroll) {
      return false;
    } else if (to.hash) {
      return new Promise((resolve => {
        Vue.nextTick(() => {
          setTimeout(() => {
            resolve({ selector: to.hash, offset: { y: 50 } });
          }, 100);
        });
      }));
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  let needsAuth = to.matched.find(record => record.meta.auth !== undefined);
  if (needsAuth) {
    needsAuth = needsAuth.meta.auth;
  } else {
    needsAuth = null;
  }
  const $loggedIn = store.getters['auth/isUserLoggedIn'];
  if (needsAuth) {
    if (!$loggedIn) {
      next({
        name: 'login',
        query: { redirect: to.fullPath },
      });
    } else if (from.query.redirect && from.query.redirect !== to.fullPath) {
      next(from.query.redirect);
    } else {
      next();
    }
  } else if (needsAuth === false && $loggedIn && from.query.redirect !== to.fullPath) {
    next(from.query.redirect || '/');
  } else {
    next();
  }
});

  return router;
};
