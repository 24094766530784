import 'rewart-frontend-library/src/registerServiceWorker';

import minViewport from '@tofandel/min-viewport';
import rewartLib from 'rewart-frontend-library/src/main';
import supportsWebp from 'rewart-frontend-library/src/utils/supportsWebp';
import VueMask from 'v-mask';
import Vue from 'vue';
import VueGtag from 'vue-gtag';

import App from './App.vue';
import i18n, { switchLocale } from './plugins/i18n';
import routerFn from './plugins/router';
import store from './plugins/store';
import vuetify from './plugins/vuetify';
import routes from './routes';

const router = routerFn(routes);

Vue.use(VueGtag, {
  bootstrap: !window.__PRERENDER_INJECTED,
  config: {id: process.env.VUE_APP_ANALYTICS},
}, router);

Vue.prototype.$iframe = false;
Vue.use(VueMask);

Vue.config.productionTip = false;

Vue.use(rewartLib, {
  vuetify,
  i18n,
  store,
  router,
});

minViewport(400);

const vue = new Vue({
  router,
  store,
  vuetify,
  i18n,
  data() {
    return {
      webp: true,
    };
  },
  created() {
    supportsWebp().catch(() => {
      this.webp = false;
      document.getElementsByTagName('html')[0].classList.add('nowebp');
    });
    if (!window.__PRERENDER_INJECTED) {
      const sc = document.getElementsByTagName('script')[0];


      this.$gtag.query('consent', 'default', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        wait_for_update: 500,
      });

      this.$gtag.query('config', 'DC-10535295', {send_page_view: false});
      this.$gtag.query('config', 'AW-457274233', {send_page_view: false});
      this.$gtag.event('conversion', {
        allow_custom_scripts: true,
        send_to: 'DC-10535295/invmedia/molre0+standard',
      });
      this.$gtag.event('conversion', {
        allow_custom_scripts: true,
        send_to: 'AW-457274233/6_27CObDre4BEPnmhdoB',
      });
    }
  },
  render: h => h(App),
});

switchLocale().then(() => vue.$mount('#app'));
