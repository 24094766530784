
import HamburgerMenu from 'rewart-frontend-library/src/components/HamburgerMenu';
import UsesPromoPage from 'rewart-frontend-library/src/mixins/UsesPromoPage';
import { Promotion } from 'rewart-frontend-library/src/models';

import Button from './Button';

/**
 * This is the site header
 */
export default {
  name: 'Header',
  components: { HamburgerMenu, Button },
  mixins: [UsesPromoPage],
  data() {
    return {
      noSvg: false,
      showMobileNav: false,
      isHydrated: false,
    };
  },
  computed: {
    breakpoint() { // just an example, could be one specific value if that's all you need
      return this.isHydrated
          ? this.$vuetify.breakpoint
          : {mobile: false};
    },
    currentPromotion() {
      return Promotion.getCurrent(this.promotionId, this.now);
    },
  },
  watch: {
    $route() {
      this.showMobileNav = false;
    },
  },

  mounted() {
    this.isHydrated = true;
    if (!window.__PRERENDER_INJECTED) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    }
  },
  created() {
    Promotion.load();
  },
};
