
import UsesPromoPage from 'rewart-frontend-library/src/mixins/UsesPromoPage';

/**
 * This is the site footer
 */
export default {
  name: 'Footer',
  mixins: [UsesPromoPage],
};
